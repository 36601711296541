import React from "react";
import {Link} from "react-router-dom";
import { connect } from "react-redux";



class HeroesCard extends React.Component{

state = {
	heroesList: [],
}




checkImage = (e) => {
	e.target.src = "https://design-system.immutable.com/currency_icons/currency--erc20.svg";
}

imageSwitch(){
	if(this.props.HeroesData.buy.type === "ETH"){
		//console.log("eth");
		return <img className="" src="https://design-system.immutable.com/currency_icons/currency--eth.svg" alt={this.props.HeroesData.sell.data.properties.name} style={{width: "14px", marginTop: "-3px", marginRight: "2px"}} />
	}
	else if(this.props.HeroesData.buy.type === "ERC20"){
		return this.props.tokenGlobal.tokenList.map((val) => {
			if(val.token_address === this.props.HeroesData.buy.data.token_address){
				return <img className="" src={val.image_url} onError={this.checkImage} alt={this.props.HeroesData.sell.data.properties.name} style={{width: "14px", marginTop: "-3px", marginRight: "2px"}} />
			}
		})
	}

}

nameSwitch = () => {
	if(this.props.HeroesData.buy.type === "ETH"){
		return <span className="">ETH</span>
	}else if(this.props.HeroesData.buy.type === "ERC20"){
		return this.props.tokenGlobal.tokenList.map((val) => {
			if(val.token_address === this.props.HeroesData.buy.data.token_address){
				return <span className="">{val.symbol}</span>
			}
		})
	}
}



componentDidMount(){
	//this.getTokenAddres()
	//this.props.getTokenData()
}


render(){
	return(
		<div className="col-6 col-md-2 mt-3 " style={{paddingBottom: "10px", wordBreak: "break-all"}}>
		<Link to={`/assets-heroes/${this.props.HeroesData.sell.data.token_id}`}>
		<div className="card cardImage border border-0 bg-light setCard" style={{width: "100%"}}>
		 <img className="card-img-top setCard cardImage" src={this.props.HeroesData.sell.data.properties.image_url} alt="heroes_card"/>
			<div className="text mt-1 mx-2" style={{paddingBottom: "15px"}}>
				<p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
				<span style={{fontSize: "11px", fontWeight: "bold", color:"grey"}}>Guild of Guardians Heroes</span><br />
				<span className="h5" style={{fontSize: "13px", fontWeight: "bold"}}>{this.props.HeroesData.sell.data.properties.name} #{this.props.HeroesData.sell.data.token_id}</span> <br />
				<span style={{fontSize: "12px", fontWeight: "bold", color: "grey"}}>{this.imageSwitch()}{(this.props.HeroesData.buy.data.quantity/(10**this.props.HeroesData.buy.data.decimals)).toLocaleString(undefined, {maximumFractionDigits: 3})} {this.nameSwitch()}</span> <br />
				</p>
			</div>
		</div>
		</Link>
		</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		tokenGlobal: state.token,
	}
}


export default connect(mapStateToProps)(HeroesCard);