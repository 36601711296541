import React from "react";
import "../assets/css/styles.css"
import {API_URL} from "../constants/API"
import Axios from "axios"
import {Link} from "react-router-dom";
import { getTokenData } from "../redux/actions/token"
import { connect } from "react-redux";
import {API_BACKEND} from "../constants/API"
import moment from "moment"
import ChartBarAll from '../components/ChartBarAll';


import TransferCard from "../components/TransferCard";
import TradesCard from "../components/TradesCard";
import HeroesCard from "../components/HeroesCard"
import OtherCard from "../components/OtherCard"
import PetsCard from "../components/PetsCard"
import AvatarCard from "../components/AvatarCard"


class Home extends React.Component{
	state = {
		heroesList: [],
		petsList: [],
		avatarList: [],
		tradesList: [],
		transferList: [],
		otherList: [],
		dataAllTx: [],
        dataAllPrice: [],
        dataTimeList: [],
        dataHeroes: [],
        dataPets: [],
        dataAvatar: [],
        dataOther: [],
        txHeroes: 0,
        txPets: 0,
        txAvatar: 0,
        txOther: 0,
}

	fetchHeroes = () => {
		Axios.get(`${API_URL}/orders?page_size=6&status=active&sell_token_type=ERC721&sell_token_address=0xee972ad3b8ac062de2e4d5e6ea4a37e36c849a11`)
		.then((result) => {
			//alert("Get berhasil")
			this.setState({heroesList: result.data.result})
			//console.log(this.state.heroesList);
		})
		.catch(() => {
			alert("Server Error Problem")
		})
	}


	fetchOther = () => {
		Axios.get(`${API_URL}/orders?page_size=6&status=active&sell_token_type=ERC721&sell_token_address=0x56a900b85d309e0a981d59377ea76f12dcd4b8de`)
		.then((result) => {
			this.setState({otherList: result.data.result})
			//console.log(this.state.otherList);
		}).catch(() => {
			alert("Server Error Problem")
		})
	}

	fetchPets = () => {
		Axios.get(`${API_URL}/orders?page_size=6&status=active&sell_token_type=ERC721&sell_token_address=0xf797fa8b22218f4a82286e28a2727cd1680f4237`)
		.then((result) => {
			this.setState({petsList: result.data.result})
		}).catch(() => {
			alert("Server Error Problem")
		})
	}

	fetchAvatar = () => {
		Axios.get(`${API_URL}/orders?page_size=6&status=active&sell_token_type=ERC721&sell_token_address=0x83f120283c30c796ebe9216ccaf6718c31213681`)
		.then((result) => {
			this.setState({avatarList: result.data.result})
		}).catch(() => {
			alert("Server Error Problem")
		})
	}

	fetchTransfer = () => {
		Axios.get(`${API_URL}/transfers?page_size=5&token_address=0x9ab7bb7fdc60f4357ecfef43986818a2a3569c62`)
		.then((result) => {
			this.setState({
				transferList: result.data.result
			})
		}).catch(()=>{
			alert("Server Error Problem")
		})
	}

	fetchTrades = () => {
		Axios.get(`${API_URL}/trades?page_size=2&party_a_token_address=0x9ab7bb7fdc60f4357ecfef43986818a2a3569c62`)
		.then((result) => {
			this.setState({
				tradesList: result.data.result,
			})
		})
		.catch(() => {
			alert("Server Error Problem")
		})
	}
	

	renderFetchHeroes = () => {
		return this.state.heroesList.map((val) => {
			return <HeroesCard HeroesData={val}/>
		})
	}

	renderFetchOther = () => {
		return this.state.otherList.map((val) => {
			return <OtherCard OtherData={val}/>
		})
	}

	renderFetchPets = () => {
		return this.state.petsList.map((val) => {
			return <PetsCard PetsData={val}/>
		})
	}

	renderFetchAvatar = () => {
		return this.state.avatarList.map((val) => {
			return <AvatarCard AvatarData={val}/>
		})
	}

	renderTransfer = () => {
		return this.state.transferList.map((val)=> {
			return <TransferCard TransferData={val}/>
		})
	}

	renderTrades = () => {
		return this.state.tradesList.map((val) => {
			return <TradesCard TradesData={val}/>
		})
	}

	thisAllCard = () => {
		this.fetchHeroes()
		this.fetchOther()
		this.fetchPets()
		this.fetchAvatar()
		this.fetchTransfer()
		//this.fetchTrades()
		this.props.getTokenData()
	}

 
	 fetchDataAll = async() => {
		 let dataTimeList = []
		 await Axios.get(`${API_BACKEND}/dataAll`)
		 .then(result => {
			 result.data.timeList.map(data => {
				let getDate = data.split("T")
				return dataTimeList.push(moment(getDate[0]).format("MMMM D"))
			 })
			 this.setState({
				 dataAllTx: result.data.dataTotalTx, 
				 dataAllPrice: result.data.dataTotalPrice,
				 dataTimeList,
			 })
		 })
	 }

	 fetchDataMonth = async() => {
        await Axios.get(`${API_BACKEND}/dataHeroes?data=month`)
        .then(result => {
            this.setState({txHeroes: result.data.countData[0].countData, dataHeroes: result.data.heroes})
        })

        await Axios.get(`${API_BACKEND}/dataPets?data=month`)
        .then(result => {
            this.setState({txPets: result.data.countData[0].countData, dataPets: result.data.pets})
        })

        await Axios.get(`${API_BACKEND}/dataAvatar?data=month`)
        .then(result => {
            this.setState({txAvatar: result.data.countData[0].countData, dataAvatar: result.data.avatar})
        })

        await Axios.get(`${API_BACKEND}/dataOther?data=month`)
        .then(result => {
            this.setState({txOther: result.data.countData[0].countData, dataOther: result.data.other})
        })
    }

	componentDidMount(){
		document.title = "Guild of Guardians Scan | Guild of Guardians Asset Tracker on Immutable X"
		this.thisAllCard()
		this.fetchDataMonth()
		this.fetchDataAll()
	}

	render(){
		return(
			<div className="setBody mx-4 mt-4" style={{wordBreak: "break-all"}}>
				{
					//console.log(this.state.tradesList)
				}
				{
					this.state.heroesList.length > 0 ?
					<div className="">
					<div className="mt-4" style={{paddingBottom: "10px"}}>
						<ChartBarAll dataAllTx={this.state.dataAllTx} dataAllPrice={this.state.dataAllPrice} 
						dataTimeList={this.state.dataTimeList} txHeroes={this.state.txHeroes} txPets={this.state.txPets}
						txAvatar={this.state.txAvatar} txOther={this.state.txOther}
						/>
					<div class="d-flex justify-content-between w-100">
						<p style={{fontWeight: "bold", fontSize: "20px"}}>Heroes</p>
						<a className="header-link" href="/heroes">
						<p style={{fontWeight: "bold", fontSize: "14px"}}>See All <i class="bi bi-arrow-right"></i></p>
						</a>
					</div>	
					 <div className="row">
						{this.renderFetchHeroes()}
					 </div>
					</div>

					<div className="mt-3" style={{paddingBottom: "10px"}}>
					<div class="d-flex justify-content-between w-100">
					<p style={{fontWeight: "bold", fontSize: "20px"}}>Pets</p>
					<a className="header-link" href="/pets">
					<p style={{fontWeight: "bold", fontSize: "14px"}}>See All <i class="bi bi-arrow-right"></i></p>
					</a>
					</div>	
					 <div className="row">
						{this.renderFetchPets()}
					 </div>
					</div>

					<div className="mt-3" style={{paddingBottom: "10px"}}>
					<div class="d-flex justify-content-between w-100">
					<p style={{fontWeight: "bold", fontSize: "20px"}}>Avatars</p>
					<a className="header-link" href="/avatar">
					<p style={{fontWeight: "bold", fontSize: "14px"}}>See All <i class="bi bi-arrow-right"></i></p>
					</a>
					</div>	
					 <div className="row">
						{this.renderFetchAvatar()}
					 </div>
					</div>

					<div className="mt-3" style={{paddingBottom: "10px"}}>
					<div class="d-flex justify-content-between w-100">
					<p style={{fontWeight: "bold", fontSize: "20px"}}>Others</p>
					<a className="header-link" href="/other">
					<p style={{fontWeight: "bold", fontSize: "14px"}}>See All <i class="bi bi-arrow-right"></i></p>
					</a>
					</div>	
					 <div className="row">
						{this.renderFetchOther()}
					 </div>
					</div>

					
					<div className="shadow-sm p-3  bg-white rounded mt-3">
					<div className="col-12 col-md-12">
					<p style={{textAlign: "left"}}>
						<span style={{fontSize: "16px", fontWeight: "bold"}}>Latest Transaction</span>
					<span style={{float: "right"}}>
					<a href="/transfer">
					<span style={{fontWeight: "bold", fontSize: "14px"}}>See All <i class="bi bi-arrow-right"></i></span>
					</a>
					</span>
					</p>
					</div>
					<hr />
					<div className="mt-4">
					{
					this.renderTransfer()
					}
					{
						//this.renderTrades()
					}
					</div>
					</div>

				</div>
					: 
					<div className="content setBody" style={{"textAlign": "center", margin: "0 auto", marginTop: "275px"}}>
					<div class="o1"></div>
					<div class="o2"></div>
					<div class="o3"></div>
				   </div>
				}
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		tokenGlobal: state.token,
	}
}

const mapDispatchToProps = {
	getTokenData,
}

export default connect(mapStateToProps,mapDispatchToProps)(Home);