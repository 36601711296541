import React from "react";
import { Bar, Doughnut, } from "react-chartjs-2"
import { Chart as ChartJS } from "chart.js/auto"
import moment from "moment"

class ChartBarAll extends React.Component {
    render(){
        return(
            <div className="row mb-4 chart-section">
                    <div className="col-12 col-md-6 mb-4">
                        <div className="chart mx-2 border">
                        <p className="text-center text-sm mt-2 mb-2">Volume Days on {moment().format("MMMM YYYY")} $USDC</p>
                            <div className="mx-2">
                                <Bar
                                data={{
                                    labels: [...this.props.dataTimeList],
                                    datasets: [{
                                        label: `Price Volume`,
                                        type: "bar",
                                        data: [...this.props.dataAllPrice],
                                        barThickness: 40,   
                                        backgroundColor: [
                                            'rgba(255, 99, 132, 0.2)',
                                            'rgba(54, 162, 235, 0.2)',
                                            'rgba(255, 206, 86, 0.2)',
                                            'rgba(75, 192, 192, 0.2)',
                                            'rgba(153, 102, 255, 0.2)',
                                            'rgba(255, 159, 64, 0.2)', 
                                        ],
                                        borderColor: [
                                            'rgba(255, 99, 132, 1)',
                                            'rgba(54, 162, 235, 1)',
                                            'rgba(255, 206, 86, 1)',
                                            'rgba(75, 192, 192, 1)',
                                            'rgba(153, 102, 255, 1)',
                                            'rgba(255, 159, 64, 1)'
                                        ],
                                        borderWidth: 1,
                                        hoverBorderWidth: 2,
                                    },
                                ]
                                }}
                                height={270}
                                options={{
                                    maintainAspectRatio: false,
                                    scales: {
                                        y: {
                                            beginAtZero: false
                                        }
                                    }
                                }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 mb-4">
                        <div className="chart mx-2 border">
                            <p className="text-center text-sm mt-2 mb-2">Tx Amount on {moment().format("MMMM YYYY")} $USDC</p>
                            <div className="mx-2">
                                <Doughnut 
                                data={{
                                    labels: ['Heroes', 'Pets', 'Avatar', 'Other',],
                                    datasets: [{
                                        label: `Volume on ${moment().format("MMMM YYYY")} $USDC`,
                                        data: [this.props.txHeroes, this.props.txPets, this.props.txAvatar, this.props.txOther],
                                        barThickness: 10,   
                                        backgroundColor: [
                                            'rgba(255, 99, 132, 0.2)',
                                            'rgba(54, 162, 235, 0.2)',
                                            'rgba(255, 206, 86, 0.2)',
                                            'rgba(75, 192, 192, 0.2)',
                                            'rgba(153, 102, 255, 0.2)',
                                            'rgba(255, 159, 64, 0.2)'
                                        ],
                                        borderColor: [
                                            'rgba(255, 99, 132, 1)',
                                            'rgba(54, 162, 235, 1)',
                                            'rgba(255, 206, 86, 1)',
                                            'rgba(75, 192, 192, 1)',
                                            'rgba(153, 102, 255, 1)',
                                            'rgba(255, 159, 64, 1)'
                                        ],
                                        borderWidth: 1,
                                        hoverBorderWidth: 2,
                                    },
                                ]
                                }}
                                height={270}
                                options={{
                                    maintainAspectRatio: false,
                                    scales: {
                                        y: {
                                            beginAtZero: false
                                        }
                                    }
                                }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

export default ChartBarAll