import React from "react"
import "../assets/css/styles.css"
import moment from "moment"
import { getTokenData } from "../redux/actions/token"
import { connect } from "react-redux";
import {Link} from 'react-router-dom';
import {API_URL} from "../constants/API"
import Axios from "axios"

class TradesCard extends React.Component { 

    state = {
        orderData: [],
        orderBuyData: [],
    }
    
    checkImage = (e) => {
		e.target.src = "https://design-system.immutable.com/currency_icons/currency--erc20.svg";
	}

	imageSwitch(){
		if(this.props.TradesData.a.token_type === "ETH"){
			//console.log("eth");
			return <img className="mx-1" src="https://design-system.immutable.com/currency_icons/currency--eth.svg" alt="coin icon" style={{width: "18px"}} />
		}
		else if(this.props.TradesData.a.token_type === "ERC20"){
			return this.props.tokenGlobal.tokenList.map((val) => {
				if(val.token_address === this.props.TradesData.a.token_address){
					return <img className="mx-1" src={val.image_url} onError={this.checkImage} alt="coin icon" style={{width: "18px"}} />
				}
			})
		}
	}

    fetchOrders = () => {
        Axios.get(`${API_URL}/orders/${this.props.TradesData.a.order_id}`)
        .then((result) => {
            this.setState({
                orderData: result.data,
                orderBuyData: result.data.buy.data
            })
        })
    }

    componentDidMount(){
        this.props.getTokenData()
        this.fetchOrders()
    }

	render() {
        return(
                <div className="row" style={{fontSize: "14px", wordBreak: "break-all"}}>
                    {
                        //console.log(this.state.orderBuyData.id)
                    }
                    <div className="col-8 col-md-2" style={{fontWeight: "bold"}}>
                    <Link to={`/td/${this.props.TradesData.transaction_id}`}>
                    <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}><i class="bi bi-check-circle-fill mx-2" style={{color: "#2081e2"}}></i>{parseInt(this.props.TradesData.transaction_id).toLocaleString(undefined, {maximumFractionDigits: 3})}</p>
                    </Link>
                    </div>
                    <div className="col-4 col-md-2" style={{fontSize: "14px"}}>
                    <p><i class="bi bi-clock-fill mx-2" style={{color: "#2081e2", fontSize: "12px"}}></i>{moment(this.props.TradesData.timestamp).fromNow()}</p>
                    </div>
                    <div className="col-6 col-md-2" style={{whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis", fontSize: "12px"}}>
                        <p>
                            <span style={{color: "black",whiteSpace: "nowrap",maxWidth: "125px",overflow: "hidden",textOverflow: "ellipsis"}}>{this.imageSwitch()}{
                            ((this.props.TradesData.a.sold/(10**18)).toLocaleString(undefined, {maximumFractionDigits: 5}))
                            }</span> 
                        </p>
                    </div>
                    <div className="col-12 col-md-3">
                    <a href={`address/${this.state.orderData.user}`}>
                    <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}><i class="bi bi-arrow-up-circle-fill mx-2" style={{color: "#DC3545"}}></i>{this.state.orderData.user}</p>
                    </a>
                    </div>

                    <div className="col-12 col-md-3">
                    <a href={`address/${this.state.orderData.user}`}>
                    <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}><i class="bi bi-arrow-down-circle-fill mx-2" style={{color: "#28A745"}}></i>{this.state.orderBuyData.id}</p>
                    </a>
                    </div>
                    <hr />
                </div>
        )
    }

}

const mapStateToProps = state => {
	return {
		tokenGlobal: state.token,
	}
}

const mapDispatchToProps = {
	getTokenData,
}

export default connect(mapStateToProps, mapDispatchToProps)(TradesCard)
