import React from "react";
import { Bar, Doughnut, } from "react-chartjs-2"
import { Chart as ChartJS } from "chart.js/auto"
import moment from "moment"


class ChartBarPcs extends React.Component {
    render(){
        return(
            <div className="section-chart mb-2 pt-2 row">
				<div className="col-12 col-md-6 mt-2 mb-2">
					<div className="mx-2 border">
					<Bar className="mx-2"
					data={{
						labels: [...this.props.timeList],
						datasets: [{
							label: `Price volumes`,
							type: "bar",
							data: [...this.props.price],
							barThickness: 50,   
							backgroundColor: [
								'rgba(255, 99, 132, 0.2)',
								'rgba(54, 162, 235, 0.2)',
								'rgba(255, 206, 86, 0.2)',
								'rgba(75, 192, 192, 0.2)',
								'rgba(153, 102, 255, 0.2)',
								'rgba(255, 159, 64, 0.2)'
							],
							borderColor: [
								'rgba(255, 99, 132, 1)',
								'rgba(54, 162, 235, 1)',
								'rgba(255, 206, 86, 1)',
								'rgba(75, 192, 192, 1)',
								'rgba(153, 102, 255, 1)',
								'rgba(255, 159, 64, 1)'
							],
							borderWidth: 1,
							hoverBorderWidth: 2,
						},
					]
					}}
					height = {270}
					options={{
						maintainAspectRatio: false,
						scales: {
							y: {
								beginAtZero: false
							}
						}
					}} 
				/>
					</div>
				</div>
				<div className="col-12 col-md-6 mt-2 mb-2">
					<div className="mx-2 border">
					<Bar className="mx-2"
						data={{
							labels: [...this.props.timeList],
							datasets: [{
								label: `Tx volumes`,
								type: "line",
								data: [...this.props.tx],
								barThickness: 50,   
								backgroundColor: [
									'rgba(255, 99, 132, 0.2)',
									'rgba(54, 162, 235, 0.2)',
									'rgba(255, 206, 86, 0.2)',
									'rgba(75, 192, 192, 0.2)',
									'rgba(153, 102, 255, 0.2)',
									'rgba(255, 159, 64, 0.2)'
								],
								borderColor: [
									'rgba(255, 99, 132, 1)',
									'rgba(54, 162, 235, 1)',
									'rgba(255, 206, 86, 1)',
									'rgba(75, 192, 192, 1)',
									'rgba(153, 102, 255, 1)',
									'rgba(255, 159, 64, 1)'
								],
								borderWidth: 1,
								hoverBorderWidth: 2,
							},
						]
						}}
						height = {270}
						options={{
							maintainAspectRatio: false,
							scales: {
								y: {
									beginAtZero: false
								}
							}
						}}
					/>
					</div>
				</div>
			</div>
        )
    }
}

export default ChartBarPcs;