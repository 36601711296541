import Axios from 'axios';
import { API_URL } from '../../constants/API'

export const getPathName = (pathName) => {
    return(dispatch) => {
      dispatch({
        type: 'GET_PATH_NAME',
        data: pathName
      })
    }
  }

  export const getOrder = (limit, address, cursor) => {
    var additional = "";
    if(address) {
      additional = `&user=${address}`
    }
    if(cursor){
      additional = `${additional}&cursor=${cursor}`
    }
    return (dispatch) => {
      Axios.get(`${API_URL}/orders?page_size=${limit}${additional}`)
      .then((response) => {
        if(response.data.result){
          const ordersData = response.data;
          dispatch({
            type: 'GET_ORDER',
            status: response.status,
            data: {
              data: ordersData.result,
              nextCursor: ordersData.cursor,
              remaining: ordersData.remaining,
            }
          })
        }
      })
      .catch((e) => {
        dispatch({
          type: 'RESET_STATUS_ORDER',
          status: "300"
        })
      })
    }
  }


  export const getSell = (address, cursor) => {
    var additional = "";
    
    if(cursor){
      additional = `&cursor=${cursor}`
    }
    if(address){
      additional = `${additional}&sell_token_id=${address}`
    }
    return (dispatch) => {
      Axios.get(`${API_URL}/orders?sell_token_address=0xee972ad3b8ac062de2e4d5e6ea4a37e36c849a11&page_size=200${additional}`)
      .then((response) => {
        if(response.data.result){
          const collectionsData = response.data;
          dispatch({
            type: 'GET_SELL',
            status: response.status,
            data: {
              data: collectionsData.result,
              nextCursor: collectionsData.cursor,
              remaining: collectionsData.remaining
            },
          })
        }
      })
      .catch((e) => {
        dispatch({
          type: 'RESET_STATUS_TRANSFER',
          status: "300"
        })
      })
    }
  }

  export const getTransfer = (limit, address, cursor, contract, type) => {
    if(!limit){
      limit = 20;
    }
    var additional = "";
    if(cursor){
      additional = `&cursor=${cursor}`
    }
    if(contract && type){
      additional = `${additional}&token_address=${contract}&token_type=${type}`
    }
    if(address){
      additional = `${additional}&user=${address}`
    }
    return (dispatch) => {
      Axios.get(`${API_URL}/transfers?page_size=${limit}${additional}`)
      .then((response) => {
        if(response.data.result){
          const transfersData = response.data;
          if(contract && type === "ERC20"){
            dispatch({
              type: 'GET_TRANSFER',
              status: response.status,
              data: {
                data: transfersData.result,
                nextCursor: transfersData.cursor,
                remaining: transfersData.remaining
              }
            })
          }else{
            var detailTransfer = [];
            transfersData.result.forEach(async(transfer) => {
              if(transfer.token.type === "ERC721"){
                const response = await Axios.get(`${API_URL}/assets/${transfer.token.data.token_address}/${transfer.token.data.token_id}`)
                if(response.data){
                  detailTransfer.push({
                    transaction_id: transfer.transaction_id,
                    type: "ERC721",
                    from: transfer.user,
                    to: transfer.receiver,
                    timestamp: transfer.timestamp,
                    token_name: response.data.name,
                    token_id: transfer.token.data.token_id,
                    token_address: transfer.token.data.token_address,
                    quantity: transfer.token.data.quantity,
                    image_url: response.data.image_url,
                  })
                }
              }else if(transfer.token.type === "ERC20"){
                const response = await Axios.get(`${API_URL}/tokens/${transfer.token.data.token_address}`)
                if(response.data){
                  detailTransfer.push({
                    transaction_id: transfer.transaction_id,
                    from: transfer.user,
                    to: transfer.receiver,
                    type: "ERC20",
                    timestamp: transfer.timestamp,
                    token_address: transfer.token.data.token_address,
                    quantity: transfer.token.data.quantity,
                    decimal: response.data.decimals,
                    symbol: response.data.symbol,
                    image_url: response.data.image_url
                  })
                }
              }else{
                detailTransfer.push({
                  transaction_id: transfer.transaction_id,
                  from: transfer.user,
                  to: transfer.receiver,
                  timestamp: transfer.timestamp,
                  symbol: "ETH",
                  token_address: transfer.token.data.token_address,
                  quantity: transfer.token.data.quantity,
                })
              }
              
              if(detailTransfer.length === transfersData.result.length) {
                detailTransfer.sort(function(a, b) {
                  return a.transaction_id - b.transaction_id
                });
                dispatch({
                  type: 'GET_TRANSFER',
                  status: response.status,
                  data: {
                    data: detailTransfer.reverse(),
                    nextCursor: transfersData.cursor,
                    remaining: transfersData.remaining
                  }
                })
              }
            })
          }
        }
      })
      .catch((e) => {
        dispatch({
          type: 'RESET_STATUS_TRANSFER',
          status: "300"
        })
      })
    }
  }


  export const getAssetOutTransfer = (address, token_id, cursor) => {
    var additional = ""
    if(cursor){
      additional = `&cursor=${cursor}`
    }

    return (dispatch) => {
      Axios.get(`${API_URL}/assets/${address}/${token_id}${additional}`)
      .then((response) => {
        if(response.data){
          const assetOutTransferData = response.data
          dispatch({
            type: "GET_ASSET_TRANSFER",
            status: response.status,
            data: {
              data: assetOutTransferData.result,
              dataAll: assetOutTransferData,
              dataMeta: assetOutTransferData.metadata.image_url,
              dataMetaName: assetOutTransferData.metadata.name,
              tokenAddress: assetOutTransferData.token_address,
              nextCursor: assetOutTransferData.cursor,
              remaining: assetOutTransferData.remaining,
            }
          })
        }
      })
      .catch(() => {
        //console.log("")
      })
    }
  }

  export const getInventory = (limit, address, cursor) => {
    var additional = "";
    if(cursor){
      additional = `&cursor=${cursor}`
    }
    if(address){
      additional = `${additional}&user=${address}`
    }
    return (dispatch) => {
      Axios.get(`${API_URL}/assets?page_size=${limit}${additional}`)
      .then((response) => {
        if(response.data.result){
          const inventorysData = response.data;
          dispatch({
            type: 'GET_INVENTORY',
            status: response.status,
            data: {
              data: inventorysData.result,
              nextCursor: inventorysData.cursor,
              remaining: inventorysData.remaining
            },
          })
        }
      })
      .catch((e) => {
        dispatch({
          type: 'RESET_STATUS_TRANSFER',
          status: "300"
        })
      })
    }
  }

  export const getCollection = (cursor) => {
    var additional = "";
    if(cursor){
      additional = `&cursor=${cursor}`
    }
    return (dispatch) => {
        Axios.get(`${API_URL}/orders?status=active&sell_token_type=ERC721&sell_token_address=0xee972ad3b8ac062de2e4d5e6ea4a37e36c849a11&page_size=100${additional}`)
      .then((response) => {
        if(response.data.result){
          const collectionsData = response.data;
          dispatch({
            type: 'GET_COLLECTION',
            status: response.status,
            data: {
              data: collectionsData.result,
              nextCursor: collectionsData.cursor,
              remaining: collectionsData.remaining
            },
          })
        }
      })
      .catch((e) => {
        dispatch({
          type: 'RESET_STATUS_TRANSFER',
          status: "300"
        })
      })
    }
  }

  export const getCollectionTransfer = (cursor) => {
    var additional = "";
    if(cursor){
      additional = `&cursor=${cursor}`
    }
    return (dispatch) => {
        Axios.get(`${API_URL}/transfers?page_size=199&token_address=0x9ab7bb7fdc60f4357ecfef43986818a2a3569c62${additional}`)
      .then((response) => {
        if(response.data.result){
          const collectionsData = response.data;
          dispatch({
            type: 'GET_COLLECTION_TRANSFER',
            status: response.status,
            data: {
              data: collectionsData.result,
              nextCursor: collectionsData.cursor,
              remaining: collectionsData.remaining
            },
          })
        }
      })
      .catch((e) => {
        dispatch({
          type: 'RESET_STATUS_TRANSFER',
          status: "300"
        })
      })
    }
  }


  export const getCollectionOther = (cursor) => {
    var additional = "";
    if(cursor){
      additional = `&cursor=${cursor}`
    }
    return (dispatch) => {
        Axios.get(`${API_URL}/orders?status=active&sell_token_type=ERC721&sell_token_address=0x56a900b85d309e0a981d59377ea76f12dcd4b8de&page_size=100${additional}`)
      .then((response) => {
        if(response.data.result){
          const collectionsData = response.data;
          dispatch({
            type: 'GET_COLLECTION_OTHER',
            status: response.status,
            data: {
              data: collectionsData.result,
              nextCursor: collectionsData.cursor,
              remaining: collectionsData.remaining
            },
          })
        }
      })
      .catch((e) => {
        dispatch({
          type: 'RESET_STATUS_TRANSFER',
          status: "300"
        })
      })
    }
  }

  export const getCollectionPet = (cursor) => {
    var additional = "";
    if(cursor){
      additional = `&cursor=${cursor}`
    }
    return (dispatch) => {
        Axios.get(`${API_URL}/orders?status=active&sell_token_type=ERC721&sell_token_address=0xf797fa8b22218f4a82286e28a2727cd1680f4237&page_size=100${additional}`)
      .then((response) => {
        if(response.data.result){
          const collectionsData = response.data;
          dispatch({
            type: 'GET_COLLECTION_PET',
            status: response.status,
            data: {
              data: collectionsData.result,
              nextCursor: collectionsData.cursor,
              remaining: collectionsData.remaining
            },
          })
        }
      })
      .catch((e) => {
        dispatch({
          type: 'RESET_STATUS_TRANSFER',
          status: "300"
        })
      })
    }
  }

  export const getCollectionAvatar = (cursor) => {
    var additional = "";
    if(cursor){
      additional = `&cursor=${cursor}`
    }
    return (dispatch) => {
        Axios.get(`${API_URL}/orders?status=active&sell_token_type=ERC721&sell_token_address=0x83f120283c30c796ebe9216ccaf6718c31213681&page_size=100${additional}`)
      .then((response) => {
        if(response.data.result){
          const collectionsData = response.data;
          dispatch({
            type: 'GET_COLLECTION_AVATAR',
            status: response.status,
            data: {
              data: collectionsData.result,
              nextCursor: collectionsData.cursor,
              remaining: collectionsData.remaining
            },
          })
        }
      })
      .catch((e) => {
        dispatch({
          type: 'RESET_STATUS_TRANSFER',
          status: "300"
        })
      })
    }
  }

  export const getOfferHeroes = (limit, token_id, cursor) => {
      var additional = ""
      if(token_id){
        additional = `&buy_token_id=${token_id}`
      }
      if(cursor){
        additional = `${additional}&cursor=${cursor}`
      }

      return (dispatch) => {
        //Axios.get(`${API_URL}/orders?buy_token_id=${this.props.match.params.token_id}&buy_token_address=0xee972ad3b8ac062de2e4d5e6ea4a37e36c849a11&status=active
        Axios.get(`${API_URL}/orders?page_size=${limit}&buy_token_address=0xee972ad3b8ac062de2e4d5e6ea4a37e36c849a11${additional}&status=active`)
        .then((response) => {
          const OfferHeroes = response.data
          dispatch({
            type: "GET_OFFER_HEROES",
            status: "response.status",
            data: {
              data: OfferHeroes.result,
              nextCursor: OfferHeroes.cursor,
              remaining: OfferHeroes.remaining,
            }
          })
        })
        .catch((e) => {
          dispatch({
            type: "RESET_STATUS_SELL",
            status: "300"
          })
        })
      }
  }

  export const getOfferOther = (limit, token_id, cursor) => {
    var additional = ""
    if(token_id){
      additional = `&buy_token_id=${token_id}`
    }
    if(cursor){
      additional = `${additional}&cursor=${cursor}`
    }

    return (dispatch) => {
      //Axios.get(`${API_URL}/orders?buy_token_id=${this.props.match.params.token_id}&buy_token_address=0xee972ad3b8ac062de2e4d5e6ea4a37e36c849a11&status=active
      Axios.get(`${API_URL}/orders?page_size=${limit}&buy_token_address=0x56a900b85d309e0a981d59377ea76f12dcd4b8de${additional}&status=active`)
      .then((response) => {
        const OfferOther = response.data
        dispatch({
          type: "GET_OFFER_OTHER",
          status: "response.status",
          data: {
            data: OfferOther.result,
            nextCursor: OfferOther.cursor,
            remaining: OfferOther.remaining,
          }
        })
      })
      .catch((e) => {
        dispatch({
          type: "RESET_STATUS_SELL",
          status: "300"
        })
      })
    }
}


  export const getOfferPets = (limit, token_id, cursor) => {
    var additional = ""
    if(token_id){
      additional = `&buy_token_id=${token_id}`
    }
    if(cursor){
      additional = `${additional}&cursor=${cursor}`
    }

    return (dispatch) => {
      //Axios.get(`${API_URL}/orders?buy_token_id=${this.props.match.params.token_id}&buy_token_address=0xee972ad3b8ac062de2e4d5e6ea4a37e36c849a11&status=active
      Axios.get(`${API_URL}/orders?page_size=${limit}&buy_token_address=0xf797fa8b22218f4a82286e28a2727cd1680f4237${additional}&status=active`)
      .then((response) => {
        const OfferPets = response.data
        dispatch({
          type: "GET_OFFER_PETS",
          status: "response.status",
          data: {
            data: OfferPets.result,
            nextCursor: OfferPets.cursor,
            remaining: OfferPets.remaining,
          }
        })
      })
      .catch((e) => {
        dispatch({
          type: "RESET_STATUS_SELL",
          status: "300"
        })
      })
    }
  }

  export const getOfferAvatar = (limit, token_id, cursor) => {
    var additional = ""
    if(token_id){
      additional = `&buy_token_id=${token_id}`
    }
    if(cursor){
      additional = `${additional}&cursor=${cursor}`
    }

    return (dispatch) => {
      //Axios.get(`${API_URL}/orders?buy_token_id=${this.props.match.params.token_id}&buy_token_address=0xee972ad3b8ac062de2e4d5e6ea4a37e36c849a11&status=active
      Axios.get(`${API_URL}/orders?page_size=${limit}&buy_token_address=0x83f120283c30c796ebe9216ccaf6718c31213681${additional}&status=active`)
      .then((response) => {
        const OfferAvatar = response.data
        dispatch({
          type: "GET_OFFER_AVATAR",
          status: "response.status",
          data: {
            data: OfferAvatar.result,
            nextCursor: OfferAvatar.cursor,
            remaining: OfferAvatar.remaining,
          }
        })
      })
      .catch((e) => {
        dispatch({
          type: "RESET_STATUS_SELL",
          status: "300"
        })
      })
    }
  }

  


  export const getSellHeroes = (limit, token_id, cursor) => {
      var additional =  ""
      if(token_id){
        additional = `&sell_token_id=${token_id}`
      }
      if(cursor){
        additional = `${additional}&cursor=${cursor}`
      }

      return (dispatch) => {
        //Axios.get(`${API_URL}/orders?sell_token_id=${this.props.match.params.token_id}&sell_token_address=0xee972ad3b8ac062de2e4d5e6ea4a37e36c849a11&page_size=200`)
        Axios.get(`${API_URL}/orders?page_size=${limit}&sell_token_address=0xee972ad3b8ac062de2e4d5e6ea4a37e36c849a11${additional}`)
        .then((response) => {
          const sellHeroes = response.data
          dispatch({
            type: 'GET_SELL_HEROES',
            status: "response.status",
            data: {
              data: sellHeroes.result,
              nextCursor: sellHeroes.cursor,
              remaining: sellHeroes.remaining,
            }
          })
        })
        .catch((e) => {
          dispatch({
            type: "RESET_STATUS_OFFER",
            status: "300"
          })
        })
      }
  }

  export const getSellAvatar = (limit, token_id, cursor) => {
    var additional =  ""
    if(token_id){
      additional = `&sell_token_id=${token_id}`
    }
    if(cursor){
      additional = `${additional}&cursor=${cursor}`
    }

    return (dispatch) => {
      //Axios.get(`${API_URL}/orders?sell_token_id=${this.props.match.params.token_id}&sell_token_address=0xee972ad3b8ac062de2e4d5e6ea4a37e36c849a11&page_size=200`)
      Axios.get(`${API_URL}/orders?page_size=${limit}&sell_token_address=0x83f120283c30c796ebe9216ccaf6718c31213681${additional}`)
      .then((response) => {
        const sellAvatar = response.data
        dispatch({
          type: 'GET_SELL_AVATAR',
          status: "response.status",
          data: {
            data: sellAvatar.result,
            nextCursor: sellAvatar.cursor,
            remaining: sellAvatar.remaining,
          }
        })
      })
      .catch((e) => {
        dispatch({
          type: "RESET_STATUS_OFFER",
          status: "300"
        })
      })
    }
  }

  export const getSellOther = (limit, token_id, cursor) => {
    var additional =  ""
    if(token_id){
      additional = `&sell_token_id=${token_id}`
    }
    if(cursor){
      additional = `${additional}&cursor=${cursor}`
    }

    return (dispatch) => {
      //Axios.get(`${API_URL}/orders?sell_token_id=${this.props.match.params.token_id}&sell_token_address=0xee972ad3b8ac062de2e4d5e6ea4a37e36c849a11&page_size=200`)
      Axios.get(`${API_URL}/orders?page_size=${limit}&sell_token_address=0x56a900b85d309e0a981d59377ea76f12dcd4b8de${additional}`)
      .then((response) => {
        const sellOther = response.data
        dispatch({
          type: 'GET_SELL_OTHER',
          status: "response.status",
          data: {
            data: sellOther.result,
            nextCursor: sellOther.cursor,
            remaining: sellOther.remaining,
          }
        })
      })
      .catch((e) => {
        dispatch({
          type: "RESET_STATUS_OFFER",
          status: "300"
        })
      })
    }
  }


  export const getSellPets = (limit, token_id, cursor) => {
    var additional =  ""
    if(token_id){
      additional = `&sell_token_id=${token_id}`
    }
    if(cursor){
      additional = `${additional}&cursor=${cursor}`
    }

    return (dispatch) => {
      //Axios.get(`${API_URL}/orders?sell_token_id=${this.props.match.params.token_id}&sell_token_address=0xee972ad3b8ac062de2e4d5e6ea4a37e36c849a11&page_size=200`)
      Axios.get(`${API_URL}/orders?page_size=${limit}&sell_token_address=0xf797fa8b22218f4a82286e28a2727cd1680f4237${additional}`)
      .then((response) => {
        const sellPets = response.data
        dispatch({
          type: 'GET_SELL_PETS',
          status: "response.status",
          data: {
            data: sellPets.result,
            nextCursor: sellPets.cursor,
            remaining: sellPets.remaining,
          }
        })
      })
      .catch((e) => {
        dispatch({
          type: "RESET_STATUS_OFFER",
          status: "300"
        })
      })
    }
}


  export const resetStatusCollection = (status) => {
    return async (dispatch) => {
      dispatch({
        type: 'RESET_STATUS_COLLECTION',
        status
      })
    }
  }





