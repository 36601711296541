import React from "react";
import PetsCard from "../components/PetsCard"
import { getPathName,resetStatusCollection,getCollectionPet } from '../redux/actions/getData';
import { connect } from "react-redux"
import { getTokenData } from "../redux/actions/token"
import Axios from "axios";
import {API_BACKEND} from "../constants/API"
import { Tabs, Tab} from 'react-bootstrap';
import moment from "moment"
import ChartBarPcs from "../components/ChartBarPcs";

class Pets extends React.Component {

	constructor(props) {
		super(props);
	
		this.state = {
		  prevCursor: [""],
		  page: 1,
		  itemPerPage: 24,
		  maxPage: 0,
		  txPets: [],
		  pricePets: [],
		  bgColor: [],
		  borderColor: [],
		  timeList: [],
		}
	
		this.nextPage = this.nextPage.bind(this);
		this.prevPage = this.prevPage.bind(this);
	  }

	  prevPage () {
		this.setState({
		  page: this.state.page - 1
		});
		this.props.resetStatusCollection();
		this.props.getCollectionPet(this.state.prevCursor[this.state.page - 2]);
	  }
	
	  nextPage(){
		if(this.state.prevCursor.length <= this.state.page) {
		  this.setState({
			prevCursor: [...this.state.prevCursor, this.props.nextCursor],
			page: this.state.page + 1
		  });
		}else{
		  this.setState({
			page: this.state.page + 1
		  })
		}
		this.props.resetStatusCollection();
		this.props.getCollectionPet(this.props.nextCursor)
	  }

	  fetchDataPets = () => {  
		let txPets = []
		let pricePets = []
		let bgColor = []
		let borderColor = []
		let timeList = []

		for (let i = 1; i <= 10; i++){
			bgColor.push('rgba(255, 99, 132, 0.2)')
			borderColor.push('rgba(255, 99, 132, 1)')
		}

		Axios.get(API_BACKEND + "/dataPets")
		.then(result => {
			result.data.txValue.map(data => {
				data.map(val => {
					return txPets.push(val.txValue)
				})
				return data;
			})
			
			result.data.priceValue.map(data => {
				data.map(val => {
					return pricePets.push(val.price)
				})
				return data;
			})
			
		   result.data.timeList.map(data => {
			   let getDate = data.split("T")
			   return timeList.push(moment(getDate[0]).format("MMM D"))
		   })
			
		  this.setState({txPets, pricePets, bgColor, borderColor, timeList})
		})
	  }

	componentDidMount(){
		document.title = `Pets Collections`;
		this.props.getCollectionPet()
		this.props.getTokenData()
		this.fetchDataPets()
	} 

	render() {
		return(
			<div className="mt-4">
				{
				this.props.collections.length > 0 ?
				<div className="summary-div pt-1">
				<div className="col-md-12 d-flex" style={{display: "block"}}>
					<div className="shadow-sm p-3 mb-5 bg-white rounded"  style={{borderRadius: "4px", paddingTop: "12px", minHeight: "400px"}}>
						<div className="row">
							<div className="col-sm-11">
							<p style={{fontWeight: "bold", fontSize: "20px"}}>Pets Collections</p>
							<hr/>
							</div>
							<span className="text-center text-muted mb-4">Days value with $USDC on {moment().format("MMMM YYYY")}</span>
							<ChartBarPcs tx={this.state.txPets} price={this.state.pricePets} 
							bgColor={this.state.bgColor} borderColor={this.state.borderColor} timeList={this.state.timeList} />
							<div className="col-sm-1">
							</div>
						</div>
						<div className="pt-4">
							<div className="row px-2">
							{	
									this.props.collections.slice(0,24).map(collection => {
										return(
									
												<PetsCard PetsData={collection} />
											
										)
									})
								
								}
							</div>
							<div className="d-flex justify-content-center mt-4">
								<div className="container-pagination justify-content-center">
									<div className="col-xs" align="center">
								</div>
								<button disabled={this.state.page === 1} className="btn btn-primary  btnblue btn-sm mx-1"  style={{width: "80px"}} onClick={this.prevPage}><i className="mx-1 bi bi-arrow-left"></i>{"Prev"}</button>
								<button disabled={this.props.dataRemaining <= 0} className="btn btn-primary  btnblue btn-sm mx-1" style={{width: "80px"}}  onClick={this.nextPage}>{"Next"}<i className="mx-1 bi bi-arrow-right"></i></button>
								</div>
							</div>
						</div>
					</div>
				</div>
			 </div>
			  : 
			  <div className="content setBody" style={{"textAlign": "center", margin: "0 auto", marginTop: "275px"}}>
			  <div class="o1"></div>
			  <div class="o2"></div>
			  <div class="o3"></div>
			</div>
			  
			}
			</div>
			
		)
	}
}

const mapStateToProps = (state) => {
	return {
	  collections: state.user.latestCollection,
	  nextCursor: state.user.nextCursor,
	  collectionRemaining: state.user.dataRemaining,
	  pathName: state.user.pathName,
	  dataHeroesList: state.user.heroesList,
	  tokenGlobal: state.token,
	}
  };

  const mapDispatchToProps = {
	getPathName,
	resetStatusCollection,
	getCollectionPet,
	getTokenData,
  };

export default connect(mapStateToProps, mapDispatchToProps)(Pets);