import React from "react"
import TransferCard from "../components/TransferCard"
import { getPathName, getCollectionTransfer, resetStatusCollection } from '../redux/actions/getData';
import { connect } from "react-redux"
import { getTokenData } from "../redux/actions/token"

class transfer extends React.Component {
    constructor(props) {
		super(props);
	
		this.state = {
		  prevCursor: [""],
		  page: 1,
		  itemPerPage: 24,
		  maxPage: 0,
          transferList: [],
		}
	
		this.nextPage = this.nextPage.bind(this);
		this.prevPage = this.prevPage.bind(this);
	  }

      renderFetchHeroes = () => {
		this.setState({transferList: this.props.collections})
		const beginningIndex = (this.state.page - 1) * this.state.itemPerPage
		const currentData = this.state.transferList.slice(beginningIndex, beginningIndex + this.state.itemPerPage)


		return currentData.map((val) => {
			return <TransferCard TransferData={val}/>
		})
	}

	prevPage () {
		this.setState({
		  page: this.state.page - 1
		});
		this.props.resetStatusCollection();
		this.props.getCollectionTransfer(this.state.prevCursor[this.state.page - 2]);
	  }
	
	  nextPage(){
		if(this.state.prevCursor.length <= this.state.page) {
		  this.setState({
			prevCursor: [...this.state.prevCursor, this.props.nextCursor],
			page: this.state.page + 1
		  });
		}else{
		  this.setState({
			page: this.state.page + 1
		  })
		}
		this.props.resetStatusCollection();
		this.props.getCollectionTransfer(this.props.nextCursor)
	  }



 componentDidMount(){
	document.title = `Transfers Detail`;
	this.props.getCollectionTransfer();
    this.renderFetchHeroes()
	this.props.getTokenData()
} 
    

    render(){
        return(
            <div className="mt-4">
				<div className="shadow-sm p-3 mb-5 bg-white rounded mt-3">
					<p style={{fontWeight: "bold", fontSize: "20px"}}>Latest Transaction</p>
					<hr />	
						<div className="card-body bg-muted">
                    {
                       this.props.collections.length > 0 ?
                        
                       <div className="mt-1">
                            {
                                this.props.collections.slice(0,15).map((val) => {
                                    return(
                                      <TransferCard TransferData={val}/>
                                    )
                                })
                            }
                       </div>
                       :<div className="content" style={{"textAlign": "center", margin: "0 auto", marginTop: "275px"}}>
                       <div class="o1"></div>
                       <div class="o2"></div>
                       <div class="o3"></div>
                     </div>
                    }
					<div className="d-flex justify-content-center mt-2">
						<div className="container-pagination justify-content-center">
							<div className="col-xs" align="center">
						</div>
							<button disabled={this.state.page === 1} className="btn btn-primary  btnblue btn-sm mx-1" style={{width: "80px"}} onClick={this.prevPage}><i className="mx-1 bi bi-arrow-left"></i>{"Prev"}</button>
							<button disabled={this.props.dataRemaining <= 0} className="btn btn-primary  btnblue btn-sm mx-1" style={{width: "80px"}} onClick={this.nextPage}>{"Next"}<i className="mx-1 bi bi-arrow-right"></i></button>
						</div>
				</div>
                </div>
				</div>
            </div>
        )
    }

}

const mapStateToProps = (state) => {
	return {
	  collections: state.user.latestCollection,
	  nextCursor: state.user.nextCursor,
	  collectionRemaining: state.user.dataRemaining,
	  pathName: state.user.pathName,
	  tokenGlobal: state.token,
	}
  };

  const mapDispatchToProps = {
	getPathName,
	getCollectionTransfer,
	resetStatusCollection,
	getTokenData,
  };


export default connect (mapStateToProps, mapDispatchToProps)(transfer)