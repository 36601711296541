import Axios from 'axios';
import { API_URL } from '../../constants/API'

export const getTokenData = () => {
  return (dispatch) => {
    Axios.get(`${API_URL}/tokens`)
    .then((result) => {
      dispatch({
        type: "SWITCH_IMAGE_TOKEN",
        payload: result.data.result,
      })
    })
    .catch(() => {
      alert('Server Error Problem')
    })
  }
}