import React from "react"
import "../assets/css/styles.css"
import moment from "moment"
import { getTokenData } from "../redux/actions/token"
import { connect } from "react-redux";
import {Link} from 'react-router-dom';
import { API_URL } from "../constants/API";
import Axios from "axios"

class TransferCard extends React.Component { 

    state = {
        assetData: [],
        asssetDataCollection: [],
    }
    
    checkImage = (e) => {
		e.target.src = "https://design-system.immutable.com/currency_icons/currency--erc20.svg";
	}

	imageSwitch(){
		if(this.props.TransferData.token.type === "ETH"){
			//console.log("eth");
			return <img className="" src="https://design-system.immutable.com/currency_icons/currency--eth.svg" alt="coin icon" style={{width: "20px"}} />
		}
		else if(this.props.TransferData.token.type === "ERC20"){
			return this.props.tokenGlobal.tokenList.map((val) => {
				if(val.token_address === this.props.TransferData.token.data.token_address){
					return <img className="mx-1" src={val.image_url} onError={this.checkImage} alt="coin icon" style={{width: "18px"}} />
				}
			})
		}
	}

    nameSwitch = () => {
        if(this.props.TransferData.token.type === "ETH"){
            return <span className="mx-1">ETH</span>
        }else if(this.props.TransferData.token.type === "ERC20"){
            return this.props.tokenGlobal.tokenList.map((val) => {
                if(val.token_address === this.props.TransferData.token.data.token_address){
                    return <span className="mx-1">{val.symbol}</span>
                }
            })
        }
    }

    fetchAsset =() => {
        Axios.get(`${API_URL}/assets/${this.props.TransferData.token.data.token_address}/${this.props.TransferData.token.data.token_id}`)
        .then((result) => {
           this.setState({
               assetData: result.data,
               assetDataCollection: result.data.collection,
           })
        })
        .catch(() => {
            this.setState({
                empty: ""
            })
        })
    }

    componentDidMount(){
        this.fetchAsset()
    }

	render() {
        return(
                <div className="row" style={{fontSize: "14px", wordBreak: "break-all"}}>
                    {
                        //console.log(this.state.assetData) 
                        //console.log(this.state.assetData.image_url)
                        //console.log(this.state.assetData.name)
                        //console.log(this.props.TransferData)
                    }
                    <div className="col-7 col-md-2" style={{fontWeight: "bold"}}>
                    <Link to={`/tx/${this.props.TransferData.transaction_id}`}>
                    <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}} className="badge rounded-pill bg-success">{parseInt(this.props.TransferData.transaction_id).toLocaleString(undefined, {maximumFractionDigits: 3})}</p>
                    </Link>
                    </div>
                    <div className="col-5 col-md-2 text-muted" style={{fontSize: "14px"}}>
                    <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}><i class="bi bi-clock-fill mx-2" style={{color: "#2081e2", fontSize: "12px"}}></i>{moment(this.props.TransferData.timestamp).fromNow()}</p>
                    </div>
                    <div className="col-6 col-md-2" style={{whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis", fontSize: "12px", fontWeight: "bold"}}>
                        {
                          this.props.TransferData.token.data.quantity === "1" ?
                          <p>
                                            
                          {      
                              this.state.assetData.image_url === null && this.props.TransferData.token.type === "ERC721" ?
                              <span className="mx-2" style={{color: "black",whiteSpace: "nowrap",maxWidth: "125px",overflow: "hidden",textOverflow: "ellipsis"}}><img className="" src="https://cdn.discordapp.com/attachments/890238141687009300/935139012425949224/ercc721.png" alt="coin icon" style={{width: "20px"}} /> {this.props.TransferData.token.data.quantity}</span> 
                              : this.state.assetData.image_url === undefined ?
                              <span className="mx-2" style={{color: "black",whiteSpace: "nowrap",maxWidth: "125px",overflow: "hidden",textOverflow: "ellipsis"}}>{this.props.TransferData.token.data.quantity}</span> 
                              :
                              <span style={{color: "black",whiteSpace: "nowrap",maxWidth: "125px",overflow: "hidden",textOverflow: "ellipsis"}}><img src={this.state.assetData.image_url} alt="image_assets" style={{width: "18px"}} className="mx-1 rounded" /> {this.props.TransferData.token.data.quantity}</span> 
                          }
                            <span> {this.state.assetData.name}</span>
                         </p>
                          : 
                          <p>
                          <span style={{color: "black",whiteSpace: "nowrap",maxWidth: "125px",overflow: "hidden",textOverflow: "ellipsis"}}>{this.imageSwitch()}{
                          ((this.props.TransferData.token.data.quantity/(10**18)).toLocaleString(undefined, {maximumFractionDigits: 8}))
                          }
                          {this.nameSwitch( )}
                          </span> 
                        </p>
                        } 
                    </div>
                    <div className="col-12 col-md-3">
                    <a href={`address/${this.props.TransferData.user}`}>
                    <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}><i class="bi bi-arrow-up mx-2" style={{color: "#DC3545"}}></i>{this.props.TransferData.user}</p>
                    </a>
                    </div>

                    <div className="col-12 col-md-3">
                    <a href={`address/${this.props.TransferData.receiver}`}>
                    <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}><i class="bi bi-arrow-down mx-2" style={{color: "#28A745"}}></i>{this.props.TransferData.receiver}</p>
                    </a>
                    </div>
                    <hr />
                </div>
        )
    }

}

const mapStateToProps = state => {
	return {
		tokenGlobal: state.token,
	}
}


export default connect(mapStateToProps)(TransferCard)
